<template>
  <div class="my-bg mt-5">
    <b-container>
      <b-row class="d-flex align-items-start justify-content-center">
        <b-col md="4">
          <div class="pt-3">
            <b-img
              src="@/assets/images/logo/logo-w.png"
              width="300"
              height="auto"
              alt="logo"
            />
          </div>
          <div class="mt-3">
            <p class="text-lightOrange">
              We believe that the best way to make sure our products are the
              best they can be is to listen to what our customers have to say.
              That’s why we’re constantly asking for feedback and publishing
              reviews by customers on our website.
            </p>
          </div>
          <div class="mt-3">
            <feather-icon icon="PhoneIcon" size="24" class="text-primary" />
            <span
              class="ml-1 font-weight-bolder text-lightOrange"
              style="font-size: 1.2rem"
              >+( 1 )202 00 3399</span
            >
          </div>
          <div class="d-flex justify-content-start mt-3">
            <b-link href=" https://www.facebook.com" target="_blank">
              <feather-icon
                icon="FacebookIcon"
                class="icon-default icon-hover"
                size="20"
              />
            </b-link>
            <b-link
              href="https://www.instagram.com"
              target="_blank"
              class="ml-2"
            >
              <feather-icon
                icon="InstagramIcon"
                class="icon-default icon-hover"
                size="20"
              />
            </b-link>
            <b-link href="https://www.youtube.com" target="_blank" class="ml-2">
              <feather-icon
                icon="YoutubeIcon"
                class="icon-default icon-hover"
                size="20"
              />
            </b-link>
            <b-link href="https://www.youtube.com" target="_blank" class="ml-2">
              <feather-icon
                icon="LinkedinIcon"
                class="icon-default icon-hover"
                size="20"
              />
            </b-link>
            <b-link href="https://www.youtube.com" target="_blank" class="ml-2">
              <feather-icon
                icon="PlayIcon"
                class="icon-default icon-hover"
                size="20"
              />
            </b-link>
            <b-link href="https://www.youtube.com" target="_blank" class="ml-2">
              <feather-icon
                icon="XIcon"
                class="icon-default icon-hover"
                size="20"
              />
            </b-link>
          </div>
        </b-col>
        <b-col md="4">
          <div class="pt-3">
            <h2 class="font-weight-bolder text-light">Categories</h2>
          </div>
          <div style="border: 1px solid #ff5e15; width: 20%" class="mt-1"></div>
          <div class="mt-2">
            <b-link
              :to="{ name: 'TermsOfServices' }"
              class="font-weight-bolder text-colorText"
            >
              <h4 class="py-50 font-weight-bolder text-lightOrange">
                Art & History
              </h4>
            </b-link>
          </div>
          <div
            style="border-bottom: 1px dotted gray; width: 60%"
            class="p-50"
          ></div>
          <div class="mt-2">
            <b-link
              :to="{ name: 'PrivacyPolicy' }"
              class="font-weight-bolder text-colorText"
            >
              <h4 class="py-50 font-weight-bolder text-lightOrange">
                Entertainment
              </h4>
            </b-link>
          </div>
          <div
            style="border-bottom: 1px dotted gray; width: 60%"
            class="p-50"
          ></div>
          <div class="mt-2">
            <b-link
              :to="{ name: 'TermsOfServices' }"
              class="font-weight-bolder text-colorText"
            >
              <h4 class="py-50 font-weight-bolder text-lightOrange">
                Food & Drink
              </h4>
            </b-link>
          </div>
          <div
            style="border-bottom: 1px dotted gray; width: 60%"
            class="p-50"
          ></div>
          <div class="mt-2">
            <b-link
              :to="{ name: 'TermsOfServices' }"
              class="font-weight-bolder"
            >
              <h4 class="py-50 font-weight-bolder text-lightOrange">
                Traveling
              </h4>
            </b-link>
          </div>
          <div
            style="border-bottom: 1px dotted gray; width: 60%"
            class="p-50"
          ></div>
        </b-col>
        <b-col md="4">
          <div class="pt-3">
            <h2 class="font-weight-bolder text-light">Recent Listings</h2>
          </div>
          <div style="border: 1px solid #ff5e15; width: 20%" class="mt-1"></div>
          <div class="d-flex align-items-center justify-content-start">
            <div class="pb-2 mt-1">
              <b-img
                src="@/assets/images/featureditem/vehicle.svg"
                width="100"
                height="100"
                alt="logo"
              />
            </div>

            <div class="ml-2">
              <h2 class="font-weight-bolder text-light">
                Mercedes S-Class 2020
              </h2>
              <h2 class="font-weight-bolder text-light">$3250.00</h2>
              <div>
                <feather-icon icon="MapPinIcon" size="18" class="text-light" />
                <span class="font-weight-bold text-light ml-50"
                  >Washington DC, USA</span
                >
              </div>
            </div>
          </div>
          <div
            style="border-bottom: 1px dotted gray; width: 60%"
            class="p-50 mt-50"
          ></div>
          <div class="d-flex align-items-center justify-content-start">
            <div class="pb-2 mt-1">
              <b-img
                src="@/assets/images/featureditem/vehicle.svg"
                width="100"
                height="100"
                alt="logo"
              />
            </div>

            <div class="ml-2">
              <h2 class="font-weight-bolder text-light">
                Mercedes S-Class 2020
              </h2>
              <h2 class="font-weight-bolder text-light">$3250.00</h2>
              <div>
                <feather-icon icon="MapPinIcon" size="18" class="text-light" />
                <span class="font-weight-bold text-light ml-50"
                  >Washington DC, USA</span
                >
              </div>
            </div>
          </div>
          <div
            style="border-bottom: 1px dotted gray; width: 60%"
            class="p-50 mt-50"
          ></div>
        </b-col>
      </b-row>
    </b-container>
    <div style="background-color: #151515" class="mt-5">
      <h6 class="py-5 text-center font-weight-bolder text-light">
        Copyright © 2024 Flfl.sa. All Rights Reserved.
      </h6>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.my-bg {
  /* background: url("~@/assets/images/footer/footer.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #1f1f1f;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
/* Default color class */
.icon-default {
  color: #f8f9fa; /* Equivalent to text-light */
}

/* Hover effect class */
.icon-hover:hover {
  color: green; /* Change this to your desired hover color */
}
</style>
