<template>
  <div>
    <Navbar />
    <b-container class="">
      <b-row class="d=flex align-items-center justify-content-center w-100">
        <b-col md="5" lg="5" sm="5" class="bg-gray rounded py-5 px-2">
          <div style="border: 4px solid #ff5e15; width: 15%" class="mb-2"></div>
          <h1 class="font-weight-bolder text-colorBlack">
            Login to your account
          </h1>
          <validation-observer ref="loginFormValidation">
            <b-form class="auth-login-form mt-4" @submit.prevent>
              <b-form-group label-for="username">
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    class="form-control-merge"
                    style="padding: 30px 0px 30px 20px"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    name="username"
                    placeholder="User ID or Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label-for="password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      style="padding: 30px 0px 30px 20px"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="red"
                class="mt-3 py-2"
                block
                @click="validateForm"
              >
                SIGN IN
              </b-button>
              <b-button type="submit" variant="blue" class="mt-2 py-2" block>
                REGISTER AN ACCOUNT
              </b-button>
            </b-form>
          </validation-observer>
          <div class="text-center">
            <p class="pt-2 text-colorBlack p-0 m-0">
              Don't have account? Please
              <b-link :to="{ name: 'SignUp' }">
                <span class="font-weight-bolder text-primary cursor-pointer">
                  <u class=""> REGISTER AN ACCOUNT </u>
                </span>
              </b-link>
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { $themeConfig } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import Navbar from "@/components/home/Navbar.vue";
import Footer from "@/components/home/Footer.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Navbar,
    Footer,
  },
  mixins: [togglePasswordVisibility, util],
  data() {
    return {
      appName: "",
      appFullName: "",
      appFullName: "",
      password: "",
      username: "",
      required,
    };
  },
  created() {
    this.appName = $themeConfig.app.appName;
    this.appFullName = $themeConfig.app.appFullName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
  },
  methods: {
    ...mapActions({ login: "appData/login" }),
    async validateForm() {
      const success = await this.$refs.loginFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      try {
        const res = await this.login({
          username: this.username,
          password: this.password,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Logged in successfully",
            icon: "success",
          });
          this.$router.push({ name: "Home" });
        }
      } catch (error) {
        this.displayError(error);
      }
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    ...mapGetters({ hasRole: "appData/hasRole" }),
  },
};
</script>

<style scoped lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.overlay {
  background: rgba(9, 65, 32, 0.7) url("~@/assets/images/slider/01.jpg");
  background-size: cover;
  position: absolute;
  background-blend-mode: multiply;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
</style>
