<template>
  <b-button
    squared
    size="lg"
    class="d-none d-xl-flex align-items-center border p-0 custom-btn"
    :style="cssProps"
  >
    <div class="h-100 custom-btn-title d-flex align-items-center">
      <h3 class="mb-0 px-2 text-white">SUBMIT LISTING</h3>
    </div>
    <span class="d-inline-block h-100 custom-btn-icon">
      <span class="d-inline-flex h-100 px-2 align-items-center">
        <feather-icon
          :icon="firstIcon"
          size="24"
          class="text-light custom-first-icon"
        />
        <feather-icon
          :icon="secondIcon"
          size="24"
          class="text-light custom-second-icon"
        />
      </span>
    </span>
  </b-button>
</template>

<script>
export default {
  props: {
    title: String,
    firstIcon: String,
    secondIcon: String,
    titleBackgroundColor: String,
    iconBackgroundColor: String,
    titleHoverBackgroundColor: String,
    iconHoverBackgroundColor: String,
  },
  computed: {
    cssProps() {
      return {
        "--title-background-color": this.titleBackgroundColor,
        "--icon-background-color": this.iconBackgroundColor,
        "--title-hover-background-color": this.titleHoverBackgroundColor,
        "--icon-hover-background-color": this.iconHoverBackgroundColor,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-btn {
  height: 55px;
  border: none !important;

  .custom-btn-title {
    background-color: var(--title-background-color);
  }
  .custom-btn-icon {
    background-color: var(--icon-background-color);
  }
}
.custom-btn:hover {
  border: none;

  .custom-btn-title {
    background-color: var(--title-hover-background-color);
  }
  .custom-btn-icon {
    background-color: var(--icon-hover-background-color);
  }
  .custom-first-icon {
    position: absolute;
    transform: translate(60px);
  }
  .custom-second-icon {
    position: relative;
    transform: translate(0px);
  }
}
.custom-first-icon {
  transition-duration: 5s;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  transition: all 500ms ease;
}
.custom-second-icon {
  position: absolute;
  transform: translate(60px);
  transition-duration: 5s;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  transition: all 500ms ease;
}
</style>
