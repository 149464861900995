<template>
  <b-container class="py-4 bg-light">
    <b-navbar toggleable="lg" type="light" variant="light" class="px-0">
      <b-navbar-brand href="#" class="d-none d-lg-block">
        <b-link :to="{ name: 'Home' }">
          <b-img
            src="@/assets/images/icons/logo.png"
            width="200"
            height="auto"
            alt="logo"
          />
        </b-link>
      </b-navbar-brand>

      <b-navbar-brand href="#" class="d-block d-lg-none order-2">
        <b-link :to="{ name: 'Home' }">
          <b-img
            src="@/assets/images/icons/logo.png"
            width="172"
            height="45"
            alt="logo"
          />
        </b-link>
      </b-navbar-brand>

      <b-nav-item
        href="#"
        class="d-none d-sm-block d-md-block d-lg-none order-3"
      >
        <b-link :to="{ name: 'Login' }">
          <feather-icon
            icon="UserIcon"
            size="24"
            class="mr-50 text-colorBlack"
          />
          <span class="text-colorBlack">Login</span>
        </b-link>
      </b-nav-item>

      <b-navbar-toggle target="nav-collapse" class="order-1">
        <template #default="{ expanded }">
          <feather-icon v-if="expanded" icon="XIcon" size="32" />
          <feather-icon v-else icon="MenuIcon" size="32" />
        </template>
      </b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav class="order-4">
        <b-navbar-nav>
          <b-nav-item :to="{ name: 'Home' }" class="d-none d-lg-block">
            <span class="text-colorBlack font-weight-bolder my-span">
              Home
            </span>
          </b-nav-item>
          <b-nav-item
            href="#"
            class="d-lg-none d-block border-bottom custom-nav-item"
          >
            <span class="text-colorBlack font-weight-bolder my-span">
              Home</span
            >
          </b-nav-item>
          <b-nav-item href="#" class="d-none d-lg-block">
            <span class="text-colorBlack font-weight-bolder my-span">
              About
            </span>
          </b-nav-item>
          <b-nav-item
            href="#"
            class="d-lg-none d-block border-bottom custom-nav-item"
          >
            <span class="text-colorBlack font-weight-bolder my-span">
              About</span
            >
          </b-nav-item>
          <b-nav-item-dropdown right class="d-none d-lg-block">
            <template #button-content>
              <span class="font-weight-bolder text-colorBlack my-span"
                >Our Listings</span
              >
            </template>
            <b-dropdown-item href="#">
              <span class="font-weight-bolder text-colorBlack">
                Home - Classic
              </span>
            </b-dropdown-item>
            <b-dropdown-item href="#">
              <span class="font-weight-bolder text-colorBlack">
                Home - Rental
              </span>
            </b-dropdown-item>
            <b-dropdown-item href="#">
              <span class="font-weight-bolder text-colorBlack">
                Home - Products
              </span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item
            href="#"
            class="d-lg-none d-block border-bottom custom-nav-item"
          >
            <span class="text-colorBlack font-weight-bolder my-span"
              >Our Listings</span
            >
          </b-nav-item>
          <b-nav-item-dropdown right class="d-none d-lg-block">
            <template #button-content>
              <span class="font-weight-bolder text-colorBlack my-span"
                >News</span
              >
            </template>
            <b-dropdown-item href="#">
              <span class="font-weight-bolder text-colorBlack">
                Home - Classic
              </span>
            </b-dropdown-item>
            <b-dropdown-item href="#">
              <span class="font-weight-bolder text-colorBlack">
                Home - Rental
              </span>
            </b-dropdown-item>
            <b-dropdown-item href="#">
              <span class="font-weight-bolder text-colorBlack">
                Home - Products
              </span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item
            href="#"
            class="d-lg-none d-block border-bottom custom-nav-item"
          >
            <span class="text-colorBlack font-weight-bolder my-span">
              News</span
            >
          </b-nav-item>
          <b-nav-item-dropdown right class="d-none d-lg-block">
            <template #button-content>
              <span class="font-weight-bolder text-colorBlack my-span"
                >Pages</span
              >
            </template>
            <b-dropdown-item href="#">
              <span class="font-weight-bolder text-colorBlack">
                Home - Classic
              </span>
            </b-dropdown-item>
            <b-dropdown-item href="#">
              <span class="font-weight-bolder text-colorBlack">
                Home - Rental
              </span>
            </b-dropdown-item>
            <b-dropdown-item href="#">
              <span class="font-weight-bolder text-colorBlack">
                Home - Products
              </span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item
            href="#"
            class="d-lg-none d-block border-bottom custom-nav-item"
          >
            <span class="text-colorBlack font-weight-bolder my-span"
              >Pages</span
            >
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto d-none d-lg-flex">
          <b-nav-item href="#" class="d-flex align-items-center">
            <b-link :to="{ name: 'Login' }">
              <feather-icon icon="UserIcon" size="24" class="text-colorBlack" />
              <span class="text-colorBlack font-weight-bolder my-span">
                Login</span
              >
            </b-link>
          </b-nav-item>
          <div class="border mx-1"></div>
          <b-nav-item href="#" class="d-flex align-items-center">
            <div class="my-span">
              <feather-icon icon="BellIcon" size="24" class="text-colorBlack" />
              <span class="text-colorBlack font-weight-bolder my-span">
                Notifications</span
              >
            </div>
          </b-nav-item>
          <CustomButton
            title="SUBMIT LISTING"
            firstIcon="MenuIcon"
            secondIcon="ChevronRightIcon"
            titleBackgroundColor="#ff5e14"
            iconBackgroundColor="#e84a02"
            titleHoverBackgroundColor="#de2929"
            iconHoverBackgroundColor="#c12525"
          />
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </b-container>
</template>

<script>
import CustomButton from "@/components/ui/CustomButton.vue";

export default {
  components: {
    CustomButton,
  },
};
</script>

<style scoped lang="scss">
.custom-nav-item a {
  padding-top: 16px;
  padding-bottom: 16px;
}
.my-span {
  font-size: 1.3rem !important;
}
.my-span:hover {
  color: #ff5e15 !important;
}
</style>
